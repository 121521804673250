import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Tabs, {TabPane} from 'rc-tabs';
import Reporte from './Reporte';
import CardEmpty from '../../../Utils/Cards/CardEmpty';

export default class ReporteDRCRecepciones extends Component {
    componentWillMount() {
        const { getReporteDRCRecepciones, drcTipoMateriaChange, reporte_drc_ingreso } = this.props;
        drcTipoMateriaChange(reporte_drc_ingreso.tipo_materia);
        getReporteDRCRecepciones();
    }

    render() {
        const {
            reporte_drc_ingreso: reporte, drcTipoMateriaChange,
            drcLatexPageChange, drcLatexFechaInicialChange, drcLatexFechaFinalChange, drcLatexFincaChange, drcLatexMateriaChange,
            drcSolidoPageChange, drcSolidoFechaInicialChange, drcSolidoFechaFinalChange, drcSolidoFincaChange, drcSolidoMateriaChange,
        } = this.props;
        let fechaInicialChange = null, fechaFinalChange = null, fincaChange = null, materiaChange = null, pageChange = null;
        let fecha_inicial = null, fecha_final = null, finca_id = null, materia_id = null, page = null, materias = [];
        switch(reporte.tipo_materia) {
            case 'LATEX':
                pageChange = drcLatexPageChange;
                fechaInicialChange = drcLatexFechaInicialChange;
                fechaFinalChange = drcLatexFechaFinalChange;
                fincaChange = drcLatexFincaChange;
                materiaChange = drcLatexMateriaChange;
                page = reporte.latex_page;
                fecha_inicial = reporte.latex_fecha_inicial;
                fecha_final = reporte.latex_fecha_final;
                finca_id = reporte.latex_finca_id;
                materia_id = reporte.latex_materia_id;
                materias = reporte.materias_latex;
                break;
            case 'SOLIDO':
                pageChange = drcSolidoPageChange;
                fechaInicialChange = drcSolidoFechaInicialChange;
                fechaFinalChange = drcSolidoFechaFinalChange;
                fincaChange = drcSolidoFincaChange;
                materiaChange = drcSolidoMateriaChange;
                page = reporte.solido_page;
                fecha_inicial = reporte.solido_fecha_inicial;
                fecha_final = reporte.solido_fecha_final;
                finca_id = reporte.solido_finca_id;
                materia_id = reporte.solido_materia_id;
                materias = reporte.materias_solido;
                break;
        }
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="DRC RECEPCIONES"
                    noBorder
                    subtitulo="Reporte">
                    <Tabs
                        activeKey={reporte.tipo_materia}
                        tabBarPosition="top"
                        onChange={tipo_materia => {drcTipoMateriaChange(tipo_materia)}}
                        renderTabBar={() => <TabBar />}
                        renderTabContent={() => <TabContent />}>
                        <TabPane tab="LÁTEX" key="LATEX">
                            <div></div>
                        </TabPane>
                        <TabPane tab="SÓLIDOS" key="SOLIDO">
                            <div></div>
                        </TabPane>
                    </Tabs>
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            fechaInicialChange={fechaInicialChange} fecha_inicial={fecha_inicial} fechaFinalChange={fechaFinalChange} fecha_final={fecha_final}
                            filtroFincaChange={fincaChange} finca_id={finca_id}
                            filtroMateriasChange={materiaChange} materias_ids={materia_id} tipo_materia={reporte.tipo_materia}
                            precioReporteAmoniaco={undefined}
                            concentracionReporteAmoniaco={undefined}
                        />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <Reporte {...this.props} pageChange={pageChange} page={page}></Reporte>
                </Card>
            </div>
        )
    }
}
