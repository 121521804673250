import React from 'react';
import {Route, Switch,} from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';

import {Login, Profile, Registro} from './common/components/LoginRegister';
import Home from './common/components/Home';
import ProtectedRoute from './ProtectedRoute';
import Examples from './common/components/Examples/Basic';
import NotFound from './common/components/layout/NotFound/NotFound';
import sendRecoverPassword from './common/components/LoginRegister/RecoverPassword/SendRecovery';
import PassRecover from './common/components/LoginRegister/ChangePassword/';

import '../assets/fonts/fonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Grids from "./common/components/Examples/Grids";
import Notificaciones from './common/components/Examples/Notificaciones';
import ExampleTabs from './common/components/Examples/Tabs/Tabs';
//Usuarios
import {CrearUsuario, UsuariosList} from './common/components/App/Usuarios'
//Bancos
import {BancoCrear, BancosListar} from './common/components/App/Bancos';
//EMAILS NOTIFICACIONES
import {EmailsCrear, EmailsListar} from './common/components/App/NotifiEmails';
//Propietarios
import {PropietarioCrear, PropietariosListar} from './common/components/App/Propietarios';
//Perfil Finca
import {PerfilCrear, PerfilesFincaList} from './common/components/App/PerfilFincas';
// Transportistas
import { TransportistaSave, TransportistasList } from './common/components/App/Transportistas';
//Vehiculos
import {VehiculoCrear, VehiculosListar} from "./common/components/App/Vehiculos";
//Tipos de vehiculo
import {TiposVehiculoCrear, TiposVehiculoListar} from "./common/components/App/TiposVehiculo";
//Pilotos
import {PilotoCrear, PilotosListar} from "./common/components/App/Pilotos";
// TiposMateriaPrima
import {TipoMateriaPrimaCrear, TiposMateriaPrimaListar} from "./common/components/App/TipoMateriaPrima";
// MateriaPrima
import {MateriaPrimaCrear, MateriaPrimaListar} from "./common/components/App/MateriaPrima";
//Tipos de pago
import {TipoPagoCrear, TiposPagoListar} from "./common/components/App/TipoPago";
// Acopios
import { AcopioSave, AcopiosList } from './common/components/App/Acopios';
//Fincas
import {FincaCrear, FincasListar, FincaVer} from "./common/components/App/Fincas";
//Bodegas
import {BodegaCrear, BodegasListar, BodegaVer} from "./common/components/App/Bodega";
//Ingresos o Recepciones
import {
    EditarPesoProducto,
    IngresoCrear,
    IngresoPesar,
    IngresosListar,
    IngresoVer,
    IngresoVerBoleta,
} from "./common/components/App/Ingresos";
import { BoletaSalida } from './common/components/App/Impresiones';
//Tanques
import {TanquesListar} from "./common/components/App/Produccion/Tanques";
//Pilas
import {PilaSalida, PilasList, PilaVer} from "./common/components/App/Produccion/Pilas";
//Almacenes
import {AlmacenesList, AlmacenSalida, AlmacenVer} from "./common/components/App/Produccion/Almacenes";

//Análisis de bodegas
import {
    AnalisisPilasListar, AnalisisPilasVer, AnalisisPilasEditar, AnalisisPilasPendienteValidacion, AnalisisPilasHistorico,
} from "./common/components/App/LaboratorioPilasTanques";

//Laboratorio de LÁTEX y SÓLIDOS
import {
    LaboratorioLatexListar, LaboratorioLatexEditar, PendientesLatexValidar, AnalisisLatexVer, HistoricoAnalisisLatex
} from "./common/components/App/LaboratorioLatex";
import {
    LaboratorioSolidoEditar, AnalisisSolidoVer, LaboratorioSolidoListar, PendientesSolidoValidar, HistoricoAnalisisSolido, LaboratorioSolidoDRC
} from "./common/components/App/LaboratorioSolidos";
//Liquidaciones
import {LiquidacionesCrear, LiquidacionesListar, LiquidacionesVer, BoletaLiquidacion} from "./common/components/App/Liquidaciones";
//Compras
import {ComprasCrear, ComprasListar} from "./common/components/App/Compras";
// Contaminantes
import {ContaminanteCrear, ContaminantesListar} from "./common/components/App/Contaminantes";
// Roles
import {RolesCrear, RolesListar} from "./common/components/App/Roles"
// Producciones
import {ProduccionesCrear, ProduccionesListar, ProduccionesVer} from "./common/components/App/ProduccionSolidos"
// Proveedores
import {ProveedoresList, CrearProveedor} from "./common/components/App/Proveedores"

import ConfiguracionEditar from "./common/components/App/Configuracion";
// Reportes
import {
    ReporteLiquidaciones, ReporteCostoFletes, ReporteRecepciones, ReporteRecepcionesAnual, ReporteDRCRecepciones, ReporteRecepcionesKgSecos,
    ReporteProducciones, ReporteVersusDRC, ReporteLaboratorio, ReporteFluctuacionPrecios, ReporteExcendenteAmoniaco,
    ReporteLiquidacionesProveedores, ReporteRecepcionesProveedores, ReporteUbicacionFincas, ReporteTrasladosMateria,
    ReporteKgSecosAnual, ReporteDRCAnual, ReporteMateriaPrimaAnual, ReporteMateriaPrimaMensual, ReporteLiquidacionSemanal,
    BitacoraListar,BitacoraDetalle, ReportePilaAlmacen, DetallePilaAlmacen, ReporteLabProveedores, ReporteNh3DrcProveedores, ReporteNh3VfaProveedores
} from './common/components/App/Reportes';

// Anulación de recepciones
import { Anulacion } from "./common/components/App/Anulacion";

// Laboratoristas
import { LaboratoristasList, LaboratoristaCrear } from "./common/components/App/Laboratoristas";

// Clientes
import { ClientesList, ClienteCrear } from "./common/components/App/Clientes";

// Despachos
import { DespachosList, DespachosCrear, despachoVerBoleta, DespachoEditar } from "./common/components/App/Despachos";

// Historico
import { HistoricoRecepciones, DetalleRecepcionHistorico, RecepcionHistoricoBoleta, HistoricoLiquidaciones, DetalleLiquidacionHistorico, LiquidacionHistoricoBoleta } from "./common/components/App/Historico"

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('../style/index.css');

/**
 * Al agregar una ruta, verificar cómo se manejaría en la función isItemSelected() del componente Sidebar.
 */
module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/registro" component={Registro}/>
                <Route exact path="/recuperar_pass" component={sendRecoverPassword}/>
                <Route exact path="/cambiar_pass/:token" component={PassRecover}/>
                <ProtectedRoute exact path="/" component={Home}/>
                <ProtectedRoute exact path="/page2" component={Examples}/>
                <ProtectedRoute exact path="/user-profile" component={Profile}/>
                <ProtectedRoute exact path="/grids" component={Grids}/>
                <ProtectedRoute exact path="/notifications" component={Notificaciones}/>
                <ProtectedRoute exact path="/tabs" component={ExampleTabs}/>

                {/*Usuarios*/}
                <ProtectedRoute exact path="/usuarios" component={UsuariosList}/>
                <ProtectedRoute exact path="/usuario/crear" component={CrearUsuario}/>
                <ProtectedRoute exact path="/usuario/:id/editar" component={CrearUsuario}/>
                {/*Bancos*/}
                <ProtectedRoute exact path="/bancos" component={BancosListar}/>
                <ProtectedRoute exact path="/banco/crear" component={BancoCrear}/>
                <ProtectedRoute exact path="/banco/:id/editar" component={BancoCrear}/>
                {/*EnvioNotificiaciones*/}
                <ProtectedRoute exact path="/notificaciones" component={EmailsListar}/>
                <ProtectedRoute exact path="/notificaciones/crear" component={EmailsCrear}/>
                <ProtectedRoute exact path="/notificaciones/:id/editar" component={EmailsCrear}/>
                {/*Propietarios*/}
                <ProtectedRoute exact path="/propietarios" component={PropietariosListar}/>
                <ProtectedRoute exact path="/propietario/crear" component={PropietarioCrear}/>
                <ProtectedRoute exact path="/propietario/:id/editar" component={PropietarioCrear}/>
                {/*Perfil finca*/}
                <ProtectedRoute exact path="/perfiles" component={PerfilesFincaList}/>
                <ProtectedRoute exact path="/perfil/crear" component={PerfilCrear}/>
                <ProtectedRoute exact path="/perfil/:id/editar" component={PerfilCrear}/>
                {/*Transportistas*/}
                <ProtectedRoute exact path="/transportistas" component={TransportistasList}/>
                <ProtectedRoute exact path="/transportista/crear" component={TransportistaSave}/>
                <ProtectedRoute exact path="/transportista/:id/editar" component={TransportistaSave}/>
                {/*Vehiculos*/}
                <ProtectedRoute exact path="/vehiculos" component={VehiculosListar}/>
                <ProtectedRoute exact path="/vehiculo/crear" component={VehiculoCrear}/>
                <ProtectedRoute exact path="/vehiculo/:id/editar" component={VehiculoCrear}/>
                {/*Tipos de vehiculo*/}
                <ProtectedRoute exact path="/tipos_vehiculo" component={TiposVehiculoListar}/>
                <ProtectedRoute exact path="/tipo_vehiculo/crear" component={TiposVehiculoCrear}/>
                <ProtectedRoute exact path="/tipo_vehiculo/:id/editar" component={TiposVehiculoCrear}/>
                {/*Pilotos*/}
                <ProtectedRoute exact path="/pilotos" component={PilotosListar}/>
                <ProtectedRoute exact path="/piloto/crear" component={PilotoCrear}/>
                <ProtectedRoute exact path="/piloto/:id/editar" component={PilotoCrear}/>
                {/* Tipos de Materia Prima */}
                <ProtectedRoute exact path="/tipos_materia_prima" component={TiposMateriaPrimaListar}/>
                <ProtectedRoute exact path="/tipo_materia_prima/crear" component={TipoMateriaPrimaCrear}/>
                <ProtectedRoute exact path="/tipo_materia_prima/:id/editar" component={TipoMateriaPrimaCrear}/>
                {/* Materia Pima */}
                <ProtectedRoute exact path="/materias_primas" component={MateriaPrimaListar}/>
                <ProtectedRoute exact path="/materia_prima/crear" component={MateriaPrimaCrear}/>
                <ProtectedRoute exact path="/materia_prima/:id/editar" component={MateriaPrimaCrear}/>
                {/* Tipos de pago */}
                <ProtectedRoute exact path="/tipos_pago" component={TiposPagoListar}/>
                <ProtectedRoute exact path="/tipo_pago/crear" component={TipoPagoCrear}/>
                <ProtectedRoute exact path="/tipo_pago/:id/editar" component={TipoPagoCrear}/>
                {/*Acopios*/}
                <ProtectedRoute exact path="/acopios" component={AcopiosList}/>
                <ProtectedRoute exact path="/acopio/crear" component={AcopioSave}/>
                <ProtectedRoute exact path="/acopio/:id/editar" component={AcopioSave}/>
                {/* Fincas */}
                <ProtectedRoute exact path="/fincas" component={FincasListar}/>
                <ProtectedRoute exact path="/finca/crear" component={FincaCrear}/>
                <ProtectedRoute exact path="/finca/:id/editar" component={FincaCrear}/>
                <ProtectedRoute exact path="/finca/:id/" component={FincaVer}/>
                {/* Bodegas */}
                <ProtectedRoute exact path="/bodegas" component={BodegasListar}/>
                <ProtectedRoute exact path="/bodega/crear" component={BodegaCrear}/>
                <ProtectedRoute exact path="/bodega/:id/editar" component={BodegaCrear}/>
                <ProtectedRoute exact path="/bodega/:id/" component={BodegaVer}/>
                {/* Tanques */}
                <ProtectedRoute exact path="/tanques" component={TanquesListar}/>
                {/* Pilas */}
                <ProtectedRoute exact path="/pilas" component={PilasList}/>
                <ProtectedRoute exact path="/pila/:id" component={PilaVer}/>
                <ProtectedRoute exact path="/pila/:id/salida" component={PilaSalida}/>
                {/*Almacenes*/}
                <ProtectedRoute exact path="/almacenes" component={AlmacenesList}/>
                <ProtectedRoute exact path="/almacen/:id" component={AlmacenVer}/>
                <ProtectedRoute exact path="/almacen/:id/salida" component={AlmacenSalida}/>
                {/* Ingresos */}
                <ProtectedRoute exact path="/ingresos" component={IngresosListar}/>
                <ProtectedRoute exact path="/ingreso/crear" component={IngresoCrear}/>
                <ProtectedRoute exact path="/ingreso/:id/editar" component={IngresoCrear}/>
                <ProtectedRoute exact path="/ingreso/:id/pesar" component={IngresoPesar}/>
                <ProtectedRoute exact path="/ingreso/:id/pesar/registrar" component={EditarPesoProducto}/>
                <ProtectedRoute exact path="/ingreso/:id/" component={IngresoVer}/>
                <ProtectedRoute exact path="/ingreso/:id/boleta_ingreso" component={IngresoVerBoleta}/>
                <ProtectedRoute exact path="/ingreso/:id/boleta_salida" component={BoletaSalida}/>
                {/* Laboratorio LATEX y validaciones */}
                <ProtectedRoute exact path="/laboratorio/analisis_latex" component={LaboratorioLatexListar}/>
                <ProtectedRoute exact path="/laboratorio/analisis_latex/:id/" component={AnalisisLatexVer}/>
                <ProtectedRoute exact path="/laboratorio/analisis_latex/:id/editar" component={LaboratorioLatexEditar}/>
                <ProtectedRoute exact path="/laboratorio/validar_drc_latex" component={PendientesLatexValidar}/>
                <ProtectedRoute exact path="/laboratorio/validar_drc_latex/:id/" component={AnalisisLatexVer}/>
                <ProtectedRoute exact path="/laboratorio/drc_validados_latex" component={HistoricoAnalisisLatex}/>
                <ProtectedRoute exact path="/laboratorio/drc_validados_latex/:id/" component={AnalisisLatexVer}/>
                {/* Laboratorio SOLIDO y validaciones */}
                <ProtectedRoute exact path="/laboratorio/analisis_solido" component={LaboratorioSolidoListar}/>
                <ProtectedRoute exact path="/laboratorio/analisis_solido/:id/" component={AnalisisSolidoVer}/>
                <ProtectedRoute exact path="/laboratorio/analisis_solido/:id/editar" component={LaboratorioSolidoEditar}/>
                <ProtectedRoute exact path="/laboratorio/validar_drc_solido" component={PendientesSolidoValidar}/>
                <ProtectedRoute exact path="/laboratorio/validar_drc_solido/:id/" component={AnalisisSolidoVer}/>
                <ProtectedRoute exact path="/laboratorio/drc_validados_solido" component={HistoricoAnalisisSolido}/>
                <ProtectedRoute exact path="/laboratorio/drc_validados_solido/:id/" component={AnalisisSolidoVer}/>
                {/*ANÁLISIS DE BODEGAS (LABORATORIO PARA BODEGAS)*/}
                <ProtectedRoute exact path="/laboratorio/analisis_pilas" component={AnalisisPilasListar}/>
                <ProtectedRoute exact path="/laboratorio/analisis_pilas/:id/" component={AnalisisPilasVer}/>
                <ProtectedRoute exact path="/laboratorio/analisis_pilas/:id/editar" component={AnalisisPilasEditar}/>
                <ProtectedRoute exact path="/laboratorio/validar_drc_pilas" component={AnalisisPilasPendienteValidacion}/>
                <ProtectedRoute exact path="/laboratorio/validar_drc_pilas/:id/" component={AnalisisPilasVer}/>
                <ProtectedRoute exact path="/laboratorio/drc_validados_pilas" component={AnalisisPilasHistorico}/>
                <ProtectedRoute exact path="/laboratorio/drc_validados_pilas/:id/" component={AnalisisPilasVer}/>
                {/* Configuración*/}
                <ProtectedRoute exact path="/configuracion" component={ConfiguracionEditar}/>
                {/* Liquidaciones */}
                <ProtectedRoute exact path="/liquidaciones" component={LiquidacionesListar}/>
                <ProtectedRoute exact path="/liquidacion/:id/generar" component={LiquidacionesCrear}/>
                <ProtectedRoute exact path="/liquidacion/:id/" component={LiquidacionesVer}/>
                <ProtectedRoute exact path="/liquidacion/:id/boleta_liquidacion" component={BoletaLiquidacion}/>
                {/* Compras */}
                <ProtectedRoute exact path="/compras" component={ComprasListar}/>
                <ProtectedRoute exact path="/compra/:id/generar" component={ComprasCrear}/>
                {/* Contaminantes */}
                <ProtectedRoute exact path="/contaminantes" component={ContaminantesListar}/>
                <ProtectedRoute exact path="/contaminante/crear" component={ContaminanteCrear}/>
                <ProtectedRoute exact path="/contaminante/:id/editar" component={ContaminanteCrear}/>
                {/* Producciones */}
                <ProtectedRoute exact path="/producciones" component={ProduccionesListar}/>
                <ProtectedRoute exact path="/produccion/crear" component={ProduccionesCrear}/>
                <ProtectedRoute exact path="/produccion/:id/editar" component={ProduccionesCrear}/>
                <ProtectedRoute exact path="/produccion/:id/" component={ProduccionesVer}/>
                {/*Proveedores*/}
                <ProtectedRoute exact path="/Proveedores" component={ProveedoresList}/>
                <ProtectedRoute exact path="/proveedor/crear" component={CrearProveedor}/>
                <ProtectedRoute exact path="/proveedor/:id/editar" component={CrearProveedor}/>

                {/* Reportes */}
                <ProtectedRoute exact path="/reporte/ingresos" component={ReporteRecepciones}/>
                <ProtectedRoute exact path="/reporte/anual_ingresos" component={ReporteRecepcionesAnual}/>
                <ProtectedRoute exact path="/reporte/drc_ingresos" component={ReporteDRCRecepciones}/>
                <ProtectedRoute exact path="/reporte/liquidaciones" component={ReporteLiquidaciones}/>
                <ProtectedRoute exact path="/reporte/costo_fletes" component={ReporteCostoFletes}/>
                <ProtectedRoute exact path="/reporte/kg_secos_ingresos" component={ReporteRecepcionesKgSecos}/>
                <ProtectedRoute exact path="/reporte/producciones" component={ReporteProducciones}/>
                <ProtectedRoute exact path="/reporte/drc_lab_vs_drc_prod" component={ReporteVersusDRC}/>
                <ProtectedRoute exact path="/reporte/laboratorio" component={ReporteLaboratorio}/>
                {/* <ProtectedRoute exact path="/reporte/fluctuacion_precios" component={ReporteFluctuacionPrecios}/> */}
                <ProtectedRoute exact path="/reporte/excedente_amoniaco" component={ReporteExcendenteAmoniaco}/>
                <ProtectedRoute exact path="/reporte/ubicacion_geografica_fincas" component={ReporteUbicacionFincas}/>
                <ProtectedRoute exact path="/reporte/traslados_materia" component={ReporteTrasladosMateria}/>
                {/* Reportes especificos adicionales */}
                <ProtectedRoute exact path="/reporte/kg_secos_anual" component={ReporteKgSecosAnual}/>
                <ProtectedRoute exact path="/reporte/drc_anual" component={ReporteDRCAnual}/>
                <ProtectedRoute exact path="/reporte/liquidacion_semanal" component={ReporteLiquidacionSemanal}/>
                <ProtectedRoute exact path="/reporte/reporte_materia_prima_anual" component={ReporteMateriaPrimaAnual}/>
                <ProtectedRoute exact path="/reporte/reporte_materia_prima_mensual" component={ReporteMateriaPrimaMensual}/>
                <ProtectedRoute exact path="/reporte/reporte_pila_almacen" component={ReportePilaAlmacen}/>
                <ProtectedRoute exact path="/reporte/reporte_pila_almacen/:id" component={DetallePilaAlmacen}/>
                {/* Bitacora */}
                <ProtectedRoute exact path="/reporte/bitacora" component={BitacoraListar}/>
                <ProtectedRoute exact path="/reporte/bitacora/:id" component={BitacoraDetalle}/>

                {/* Reportes de proveedores */}
                <ProtectedRoute exact path="/reporte/liquidaciones_proveedores" component={ReporteLiquidacionesProveedores}/>
                <ProtectedRoute exact path="/reporte/ingresos_proveedores" component={ReporteRecepcionesProveedores}/>
                <ProtectedRoute exact path="/laboratorio_prov/lab_proveedores" component={ReporteLabProveedores}/>
                <ProtectedRoute exact path="/laboratorio_prov/nh3_drc" component={ReporteNh3DrcProveedores}/>
                <ProtectedRoute exact path="/laboratorio_prov/nh3_vfa" component={ReporteNh3VfaProveedores}/>

                {/* Roles */}
                <ProtectedRoute exact path="/roles" component={RolesListar}/>
                <ProtectedRoute exact path="/rol/crear" component={RolesCrear}/>
                <ProtectedRoute exact path="/rol/:id/editar" component={RolesCrear}/>

                {/* Anulacion de Recepción */}
                <ProtectedRoute exact path="/anulacion" component={Anulacion}/>

                {/* Laboratoristas */}
                <ProtectedRoute exact path="/laboratoristas" component={LaboratoristasList}/>
                <ProtectedRoute exact path="/laboratoristas/crear" component={LaboratoristaCrear}/>
                <ProtectedRoute exact path="/laboratoristas/:id/editar" component={LaboratoristaCrear}/>

                {/* Clientes */}
                <ProtectedRoute exact path="/clientes" component={ClientesList}/>
                <ProtectedRoute exact path="/clientes/:id/editar" component={ClienteCrear}/>
                <ProtectedRoute exact path="/clientes/crear" component={ClienteCrear}/>

                {/* Despachos */}
                <ProtectedRoute exact path="/despacho" component={DespachosList}/>
                <ProtectedRoute exact path="/despacho/:id/editar" component={DespachoEditar}/>
                <ProtectedRoute exact path="/despacho/:id/" component={DespachosCrear}/>
                <ProtectedRoute exact path="/despacho/:id/boleta" component={despachoVerBoleta}/>
                <ProtectedRoute exact path="/despacho/crear" component={DespachosCrear}/>

                {/* Histórico */}
                <ProtectedRoute exact path="/historico/recepciones" component={HistoricoRecepciones}/>
                <ProtectedRoute exact path="/historico/recepciones/:id" component={DetalleRecepcionHistorico}/>
                <ProtectedRoute exact path="/historico/recepciones/:id/boleta" component={RecepcionHistoricoBoleta}/>
                <ProtectedRoute exact path="/historico/liquidaciones" component={HistoricoLiquidaciones}/>
                <ProtectedRoute exact path="/historico/liquidaciones/:id" component={DetalleLiquidacionHistorico}/>
                <ProtectedRoute exact path="/historico/liquidaciones/:id/boleta" component={LiquidacionHistoricoBoleta}/>

                <Route component={NotFound}/>
            </Switch>
        </div>
        <NotificationContainer/>
    </div>
);
