import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import moment from 'moment';
import {REGISTRO, ALMACEN, CIERRE, SOLIDO, LATEX} from '../../../utility/constants'

const LOADER = 'LOADER_PRODUCCIONES';
const DATA = 'DATA_PRODUCCIONES';
const ITEM_DATA = 'ITEM_PRODUCCIONES';
const PAGE = 'PAGE_PRODUCCIONES';
const ORDERING = 'ORDERING_PRODUCCIONES';
const SEARCH = 'SEARCH_PRODUCCIONES';
const TAB_PRODUCCIONES = 'TAB_PRODUCCIONES';
const TAB_FORM_PRODUCCIONES = 'TAB_FORM_PRODUCCIONES';
const FECHA_INICIO = 'FECHA_INICIO_PRODUCCIONES';
const FECHA_FINAL = 'FECHA_FINAL_PRODUCCIONES';
const VARIABLE_CAMBIO = 'VARIABLE_CAMBIO_PRODUCCIONES';
const VARIABLE_CAMBIO_ALMACENES = 'VARIABLE_CAMBIO_ALMACENES_PRODUCCIONES';
const NUMERO_PRODUCCION = 'NUMERO_PRODUCCION';
const FINALIZADAS_FECHA_INICIAL = 'PRODUCCIONES_FINALIZADAS_FECHA_INICIAL';
const FINALIZADAS_FECHA_FINAL   = 'PRODUCCIONES_FINALIZADAS_FECHA_FINAL';
const FINALIZADAS_PAGE          = 'PRODUCCIONES_FINALIZADAS_PAGE';

// Reporte de producciones
const REPORTE_PAGE = 'REPORTE_PAGE';
const FILTRO_REPORTE_FECHA_INICIAL_PRODUCCIONES = 'FILTRO_REPORTE_FECHA_INICIAL_PRODUCCIONES';
const FILTRO_REPORTE_FECHA_FINAL_PRODUCCIONES = 'FILTRO_REPORTE_FECHA_FINAL_PRODUCCIONES';
const FILTRO_REPORTE_MATERIA_PRIMA_PRODUCCIONES = 'FILTRO_REPORTE_MATERIA_PRIMA_PRODUCCIONES';
// Variables para el Reporte de DRC Laboratorio vs. DRC Producción
const REPORTE_PRODUCCION_VS_DRC_DATA                 = 'REPORTE_PRODUCCION_VS_DRC_DATA';
const REPORTE_PRODUCCION_VS_DRC_DATA_TOTALES         = 'REPORTE_PRODUCCION_VS_DRC_DATA_TOTALES';
const REPORTE_PRODUCCION_VS_DRC_SOLIDO_PAGE          = 'REPORTE_PRODUCCION_VS_DRC_SOLIDO_PAGE';
const REPORTE_PRODUCCION_VS_DRC_SOLIDO_FECHA_INICIAL = 'REPORTE_PRODUCCION_VS_DRC_SOLIDO_FECHA_INICIAL';
const REPORTE_PRODUCCION_VS_DRC_SOLIDO_FECHA_FINAL   = 'REPORTE_PRODUCCION_VS_DRC_SOLIDO_FECHA_FINAL';
const REPORTE_PRODUCCION_VS_DRC_SOLIDO_FINCA_ID      = 'REPORTE_PRODUCCION_VS_DRC_SOLIDO_FILTRO_FINCA_ID';
const REPORTE_PRODUCCION_VS_DRC_SOLIDO_MATERIA_ID    = 'REPORTE_PRODUCCION_VS_DRC_SOLIDO_FILTRO_MATERIA_ID';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------
const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().producciones;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;

    const tab = resource.tab;
    params.filtro = 'activas';
    if (tab === 'FINALIZADAS') {
        params.filtro = 'finalizadas';
        resource.finalizadas_fecha_inicial && (params.fecha_inicial = moment(resource.finalizadas_fecha_inicial).format("YYYY-MM-DD") + ' 00:00:00');
        resource.finalizadas_fecha_final   && (params.fecha_final   = moment(resource.finalizadas_fecha_final).format("YYYY-MM-DD") + ' 23:59:59');
        dispatch({type: FINALIZADAS_PAGE, page_finalizadas: page});
    }
    dispatch(setLoader(true));
    api.get('produccion', params).then((response) => {
        dispatch(setData(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (id, filtro='activas') => (dispatch) => {
    const params={};
    params.filtro = filtro;
    dispatch(setLoader(true));
    api.get(`${'produccion'}/${id}`, params).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('RegistroForm', response));
        dispatch(initializeForm('AlmacenForm', response));
        dispatch(initializeForm('CierreForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {

    console.log("DATA", data);

    dispatch(setLoader(true));
    api.post('produccion', data).then((response) => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push('/producciones'));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id_produccion, data) => (dispatch, getStore) => {
    const _data = _.cloneDeep(data);
    const store = getStore();
    const tab = store.producciones.tab_form;
    const params = {}

    if (tab === "REGISTRO") {
        params.paso_formulario = REGISTRO
    }
    if (tab === "ALMACEN") {
        params.paso_formulario = ALMACEN
    }
    if (tab === "CIERRE") {
        params.paso_formulario = CIERRE
    }

    console.log(_data, params);

    dispatch(setLoader(true));
    api.put(`${'produccion'}/${id_produccion}`, _data, params).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        // dispatch(push('/fincas'));
        setTabForm(tab)
        dispatch(leer(id_produccion))
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${'finca'}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const setTab = (tab) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({type: TAB_PRODUCCIONES, tab});
    if (tab === "PENDIENTES"){
        dispatch(listar(1))
    }else {
        dispatch(listar(1))
    }
};

const setTabForm = (tab_form) => (dispatch) => {
    // dispatch(setLoader(true));
    dispatch({type: TAB_FORM_PRODUCCIONES, tab_form});
};

const establecerPesoTotalProduccion = (index) => (dispatch, getStore) => {
    const form = getStore().form.CierreForm;
    const valores = _.cloneDeep(form.values);

    if (valores && valores.pallets[index]['numero_pacas']) {
        valores.pallets[index]['peso_total'] = valores.pallets[index]['numero_pacas'] * 35;
        dispatch({type: VARIABLE_CAMBIO, variable_cambio:valores})
        dispatch(initializeForm('CierreForm', valores));
    }
    else{
        valores.pallets[index]['peso_total'] = 0;
        dispatch({type: VARIABLE_CAMBIO, variable_cambio:valores})
        dispatch(initializeForm('CierreForm', valores));
    }
};

const numeroProduccion = (fecha=moment()) => async (dispatch, getStore) => {
    let año = moment(fecha).year();
    let semana = moment(fecha).isoWeek();
    let fecha_inicio_semana = moment(fecha).isoWeekday(0).format("YYYY-MM-DD");
    let fecha_final_semana = moment(fecha).isoWeekday(6).format("YYYY-MM-DD");
    let numero_prod = 0;
    let no_produccion = "";
    let params = {}

    params.fecha_inicio = fecha_inicio_semana;
    params.fecha_fin = fecha_final_semana;

    try {
        const count_producciones = await api.get('produccion/get_producciones_fechas', params);
        numero_prod = count_producciones.producciones_semana;
        numero_prod = numero_prod + 1;
        no_produccion = año + "-" + semana + "-" + numero_prod;
    } catch (e) {
        no_produccion = "No se pudo generar el numero de producción";
    }
    dispatch({type: NUMERO_PRODUCCION, no_produccion})
};

const informacionAlmacen = (index) => async (dispatch, getStore) => {
    const form = getStore().form.AlmacenForm;
    const valores = _.cloneDeep(form.values);
    let params = {};
    let existencia = 0;

    if (valores && valores.materia_almacen[index]['almacen']) {
        params.id_almacen = valores.materia_almacen[index]['almacen']['id'];

        try {
            const almacen = await api.get(`produccion/get_info_almacenes`, params);
            valores.materia_almacen[index]['materia_nombre'] = almacen.materia_nombre;
            valores.materia_almacen[index]['existencia'] = almacen.existencia;
            valores.materia_almacen[index]['existencia_antigua'] = almacen.existencia_antigua;
            existencia = almacen.existencia;
        } catch (e) {
            valores.materia_almacen[index]['materia_nombre'] = "No encontrado";
            valores.materia_almacen[index]['existencia'] = 0;
            valores.materia_almacen[index]['existencia_antigua'] = false;
        }

        if (valores.materia_almacen[index]['peso_extraido'] && !valores.materia_almacen[index]['id']) {
            if (valores.materia_almacen[index]['existencia'] > valores.materia_almacen[index]['peso_extraido']) {
                valores.materia_almacen[index]['existencia'] = existencia - valores.materia_almacen[index]['peso_extraido'];
                valores.materia_almacen[index]['guardar'] = false;
            }
            else{
                valores.materia_almacen[index]['existencia'] = existencia - valores.materia_almacen[index]['peso_extraido'];
                valores.materia_almacen[index]['guardar'] = true;
            }
        }
        dispatch({type: VARIABLE_CAMBIO_ALMACENES, variable_cambio_almacenes:valores})
        dispatch(initializeForm('AlmacenForm', valores));

    }
};

const cerrar_produccion = (id) => (dispatch) => {
    setLoader(true);
    api.put(`${'produccion'}/${id}/cerrar_produccion`).then(data => {
        NotificationManager.success('Producción cerrada con exito.', 'Éxito', 3000);
        dispatch(push('/producciones'))
    }).catch(()=> {
         NotificationManager.error('Error al cerrar la producción.', 'ERROR', 3000);
    }).finally(()=>{
        setLoader(false)
    })
};

const activar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`finca/${id}/activar`).then(() => {
        dispatch(listar());
        NotificationManager.success('Finca Activada', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarDataReportes = (page = 1) => async (dispatch, getStore) => {
    const resource = getStore().producciones;
    const params = {};
    params.page = resource.page_reporte;
    params.fecha_inicial = moment(resource.fecha_inicio_reporte).format("YYYY-MM-DD");
    params.fecha_fin = moment(resource.fecha_fin_reporte).format("YYYY-MM-DD");

    if (resource.materia_prima_id_reporte > 0) {
        params.materia_prima_id = resource.materia_prima_id_reporte;
    }
    if (params.fecha_inicial && params.fecha_fin) {
        dispatch(setLoader(true));
        try {
            const producciones_all = await api.get('produccion/get_data_reporte_producciones', params);
            params.paginar_data = true;
            const producciones = await api.get('produccion/get_data_reporte_producciones', params);
            producciones.all_results = producciones_all.results;
            producciones.all_count = producciones_all.count;
            dispatch(setData(producciones));
        } catch(error) {
        } finally {
            dispatch(setLoader(false));
        }
    }

};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('fincaForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const fechaProduccion = fecha_inicio_form => (dispatch) => {
    dispatch({type: FECHA_INICIO, fecha_inicio_form});
};
const finalizadasFechaInicialChange = finalizadas_fecha_inicial => (dispatch) => {
    dispatch({type: FINALIZADAS_FECHA_INICIAL, finalizadas_fecha_inicial})
    dispatch(listar(1))
}

const finalizadasFechaFinalChange = finalizadas_fecha_final => (dispatch) => {
    dispatch({type: FINALIZADAS_FECHA_FINAL, finalizadas_fecha_final})
    dispatch(listar(1))
}

const pageReporteChange = (page_reporte) => (dispatch) => {
    dispatch({type: REPORTE_PAGE, page_reporte});
    dispatch(listarDataReportes());
}

const filtroFechaInicioProduccion = fecha_inicio_reporte => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_FECHA_INICIAL_PRODUCCIONES, fecha_inicio_reporte});
    dispatch(listarDataReportes());
};

const filtroFechaFinProduccion = fecha_fin_reporte => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_FECHA_FINAL_PRODUCCIONES, fecha_fin_reporte});
    dispatch(listarDataReportes());
};

const filtroMateriaPrimaProduccion = (materia_prima_id_reporte, page_reporte=1) => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_MATERIA_PRIMA_PRODUCCIONES, materia_prima_id_reporte});
    dispatch({type: REPORTE_PAGE, page_reporte});
    dispatch(listarDataReportes());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().fincas.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

// Métodos para el Reporte de DRC Laboratorio vs. DRC Producción
const drcVsSolidoPageChange = solido_page => (dispatch) => {
    dispatch({type: REPORTE_PRODUCCION_VS_DRC_SOLIDO_PAGE, solido_page})
    dispatch(getReporteVsDRC(false, false));
}
const drcVsSolidoFechaInicialChange = solido_fecha_inicial => (dispatch) => {
    dispatch({type: REPORTE_PRODUCCION_VS_DRC_SOLIDO_FECHA_INICIAL, solido_fecha_inicial})
    dispatch(getReporteVsDRC())
}
const drcVsSolidoFechaFinalChange = solido_fecha_final => (dispatch) => {
    dispatch({type: REPORTE_PRODUCCION_VS_DRC_SOLIDO_FECHA_FINAL, solido_fecha_final})
    dispatch(getReporteVsDRC())
}
const drcVsSolidoFincaChange = (solido_finca_id) => (dispatch) => {
    dispatch({type: REPORTE_PRODUCCION_VS_DRC_SOLIDO_FINCA_ID, solido_finca_id})
    dispatch(getReporteVsDRC())
}
const drcVsSolidoMateriaChange = (solido_materia_id) => (dispatch) => {
    dispatch({type: REPORTE_PRODUCCION_VS_DRC_SOLIDO_MATERIA_ID, solido_materia_id})
    dispatch(getReporteVsDRC())
}
const getReporteVsDRC = (resetPage = true, getAllResults = true) => async (dispatch, getStore) => {
    // Obtiene la data para la construcción del Reporte de DRC Laboratorio vs. DRC Producción.
    // Construcción de los parámetros de búsqueda. Las fechas son requeridas
    const resource = getStore().producciones;
    const reporte = resource.reporte_vs_drc;
    const params = {};
    params.tipo_materia = reporte.tipo_materia === 'SOLIDO' ? SOLIDO : LATEX;
    switch(reporte.tipo_materia) {
        case 'SOLIDO':
            if (!!resetPage) {
                dispatch({type: REPORTE_PRODUCCION_VS_DRC_SOLIDO_PAGE, solido_page: 1});
                params.page = 1;
            } else {
                params.page = reporte.solido_page;
            }
            reporte.solido_fecha_inicial !== null && (params.fecha_inicial = moment(reporte.solido_fecha_inicial).format("YYYY-MM-DD") + ' 00:00:00');
            reporte.solido_fecha_final !== null   && (params.fecha_final = moment(reporte.solido_fecha_final).format("YYYY-MM-DD") + ' 23:59:59');
            reporte.solido_finca_id !== -1        && (params.finca_id = reporte.solido_finca_id);
            reporte.solido_materia_id !== -1      && (params.materia_id = reporte.solido_materia_id);
            break;
    }
    if (params.fecha_inicial !== undefined && params.fecha_final !== undefined) {
        // Inicio de la petición al servidor
        dispatch(setLoader(true));
        try {
            params.paginar_data = true;
            const movimientos = await api.get('produccion/get_data_reporte_vs_drc', params);
            if (!!getAllResults) {
                params.anio = moment().get('years');
                const totales = await api.get('produccion/get_totales_reporte_vs_drc', params);
                dispatch({type: REPORTE_PRODUCCION_VS_DRC_DATA_TOTALES, totales});
            }
            dispatch({type: REPORTE_PRODUCCION_VS_DRC_DATA, vs_drc: movimientos});
        } catch(error) {
            NotificationManager.error((error && error.detail) || 'Ocurrió un error mientras se intentaba recuperar la información para el reporte', 'ERROR', 3000);
        } finally {
            dispatch(setLoader(false));
        }
    }
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    setTab,
    setTabForm,
    searchChange,
    onSortChange,
    activar,
    cleanForm,
    fechaProduccion,
    establecerPesoTotalProduccion,
    cerrar_produccion,
    numeroProduccion,
    informacionAlmacen,
    finalizadasFechaInicialChange,
    finalizadasFechaFinalChange,
    filtroFechaInicioProduccion,
    filtroFechaFinProduccion,
    filtroMateriaPrimaProduccion,
    listarDataReportes,
    pageReporteChange,
    // Métodos para el Reporte de DRC Laboratorio vs. DRC Producción
    drcVsSolidoPageChange,
    drcVsSolidoFechaInicialChange,
    drcVsSolidoFechaFinalChange,
    drcVsSolidoFincaChange,
    drcVsSolidoMateriaChange,
    getReporteVsDRC,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [TAB_PRODUCCIONES]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    [TAB_FORM_PRODUCCIONES]: (state, { tab_form }) => {
        return {
            ...state,
            tab_form,
        };
    },
    [FECHA_INICIO]: (state, { fecha_inicio_form }) => {
        return {
            ...state,
            fecha_inicio_form,
        };
    },
    [VARIABLE_CAMBIO]: (state, { variable_cambio }) => {
        return {
            ...state,
            variable_cambio,
        };
    },
    [VARIABLE_CAMBIO_ALMACENES]: (state, { variable_cambio_almacenes }) => {
        return {
            ...state,
            variable_cambio_almacenes,
        };
    },
    [NUMERO_PRODUCCION]: (state, { no_produccion }) => {
        return {
            ...state,
            no_produccion,
        };
    },
    [FINALIZADAS_FECHA_INICIAL]: (state, { finalizadas_fecha_inicial }) => {
        return {
            ...state,
            finalizadas_fecha_inicial,
        };
    },
    [FINALIZADAS_FECHA_FINAL]: (state, { finalizadas_fecha_final }) => {
        return {
            ...state,
            finalizadas_fecha_final,
        };
    },
    [FINALIZADAS_PAGE]: (state, { page_finalizadas }) => {
        return {
            ...state,
            page_finalizadas,
        };
    },
    [FILTRO_REPORTE_FECHA_INICIAL_PRODUCCIONES]: (state, { fecha_inicio_reporte }) => {
        return {
            ...state,
            fecha_inicio_reporte,
        };
    },
    [FILTRO_REPORTE_FECHA_FINAL_PRODUCCIONES]: (state, { fecha_fin_reporte }) => {
        return {
            ...state,
            fecha_fin_reporte,
        };
    },
    [FILTRO_REPORTE_MATERIA_PRIMA_PRODUCCIONES]: (state, { materia_prima_id_reporte }) => {
        return {
            ...state,
            materia_prima_id_reporte,
        };
    },
    [REPORTE_PAGE]: (state, {page_reporte}) => {
        return {
            ...state,
            page_reporte
        };
    },
    // Reporte de DRC Laboratorio vs. DRC Producción
    [REPORTE_PRODUCCION_VS_DRC_DATA]: (state, {vs_drc}) => {
        return {
            ...state,
            reporte_vs_drc: {...state.reporte_vs_drc, vs_drc},
        };
    },
    [REPORTE_PRODUCCION_VS_DRC_DATA_TOTALES]: (state, {totales}) => {
        return {
            ...state,
            reporte_vs_drc: {...state.reporte_vs_drc, totales},
        };
    },
    [REPORTE_PRODUCCION_VS_DRC_SOLIDO_PAGE]: (state, {solido_page}) => {
        return {
            ...state,
            reporte_vs_drc: {...state.reporte_vs_drc, solido_page},
        };
    },
    [REPORTE_PRODUCCION_VS_DRC_SOLIDO_FECHA_INICIAL]: (state, {solido_fecha_inicial}) => {
        return {
            ...state,
            reporte_vs_drc: {...state.reporte_vs_drc, solido_fecha_inicial},
        };
    },
    [REPORTE_PRODUCCION_VS_DRC_SOLIDO_FECHA_FINAL]: (state, {solido_fecha_final}) => {
        return {
            ...state,
            reporte_vs_drc: {...state.reporte_vs_drc, solido_fecha_final},
        };
    },
    [REPORTE_PRODUCCION_VS_DRC_SOLIDO_FINCA_ID]: (state, {solido_finca_id}) => {
        return {
            ...state,
            reporte_vs_drc: {...state.reporte_vs_drc, solido_finca_id}
        };
    },
    [REPORTE_PRODUCCION_VS_DRC_SOLIDO_MATERIA_ID]: (state, {solido_materia_id}) => {
        return {
            ...state,
            reporte_vs_drc: {...state.reporte_vs_drc, solido_materia_id}
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    page_finalizadas: 1,
    finalizadas_fecha_inicial: moment().startOf('month'),
    finalizadas_fecha_final: moment().endOf('month'),
    ordering: '',
    search: '',
    tab: 'PENDIENTES',
    tab_form: 'REGISTRO',
    fecha_inicio_form: moment(),
    variable_cambio: {},
    variable_cambio_almacenes: {},
    no_produccion: "",
    fecha_inicio_reporte: moment().startOf('month'),
    fecha_fin_reporte: moment().endOf('month'),
    materia_prima_id_reporte: -1,
    page_reporte: 1,
    // Variables para el Reporte de DRC Laboratorio vs. DRC Producción
    reporte_vs_drc: {
        tipo_materia: 'SOLIDO',
        solido_page: 1,
        solido_fecha_inicial: moment().startOf('month'),
        solido_fecha_final: moment().endOf('month'),
        solido_finca_id: -1,
        solido_materia_id: -1,
        vs_drc: {
            count: 0,
            results: [],
        },
        totales: {},
    },
};

export default handleActions(reducers, initialState);

