import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Tabs, {TabPane} from 'rc-tabs';
import Reporte from './Reporte';
import CardEmpty from '../../../Utils/Cards/CardEmpty';

export default class ReporteRecepcionesKgSecos extends Component {
    componentWillMount() {
        const { getRecepcionesParaReporte } = this.props;
        getRecepcionesParaReporte();
    }

    render() {
        const {
            reporte, tipoMateriaChange,
            pageReporteLatexChange, fechaInicialLatexChange, fechaFinalLatexChange, fincaLatexChange, acopioLatexChange,
            pageReporteSolidoChange, fechaInicialSolidoChange, fechaFinalSolidoChange, fincaSolidoChange, acopioSolidoChange,
        } = this.props;
        let fechaInicialChange = null, fechaFinalChange = null, fincaChange = null, acopioChange = null, pageChange = null;
        let fecha_inicial = null, fecha_final = null, finca_id = null, acopio_id = null, page = null;
        switch(reporte.tipo_materia) {
            case 'LATEX':
                pageChange = pageReporteLatexChange;
                fechaInicialChange = fechaInicialLatexChange;
                fechaFinalChange = fechaFinalLatexChange;
                fincaChange = fincaLatexChange;
                acopioChange = acopioLatexChange;
                page = reporte.latex_page;
                fecha_inicial = reporte.latex_fecha_inicial;
                fecha_final = reporte.latex_fecha_final;
                finca_id = reporte.latex_finca_id;
                acopio_id = reporte.latex_acopio_id;
                break;
            case 'SOLIDO':
                pageChange = pageReporteSolidoChange;
                fechaInicialChange = fechaInicialSolidoChange;
                fechaFinalChange = fechaFinalSolidoChange;
                fincaChange = fincaSolidoChange;
                acopioChange = acopioSolidoChange;
                page = reporte.solido_page;
                fecha_inicial = reporte.solido_fecha_inicial;
                fecha_final = reporte.solido_fecha_final;
                finca_id = reporte.solido_finca_id;
                acopio_id = reporte.solido_acopio_id;
                break;
        }
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="RECEPCIONES (KG. SECOS)"
                    noBorder
                    subtitulo="Reporte">
                    <Tabs
                        activeKey={reporte.tipo_materia}
                        tabBarPosition="top"
                        onChange={tipo_materia => {tipoMateriaChange(tipo_materia)}}
                        renderTabBar={() => <TabBar />}
                        renderTabContent={() => <TabContent />}>
                        <TabPane tab="LÁTEX" key="LATEX">
                            <div></div>
                        </TabPane>
                        <TabPane tab="SÓLIDOS" key="SOLIDO">
                            <div></div>
                        </TabPane>
                    </Tabs>
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            fechaInicialChange={fechaInicialChange} fecha_inicial={fecha_inicial}
                            fechaFinalChange={fechaFinalChange} fecha_final={fecha_final}
                            filtroFincaChange={fincaChange} finca_id={finca_id}
                            filtroAcopioChange={acopioChange} acopio_id={acopio_id}
                            precioReporteAmoniaco={undefined}
                            concentracionReporteAmoniaco={undefined}
                        />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <Reporte {...this.props} pageChange={pageChange} page={page}></Reporte>
                </Card>
            </div>
        )
    }
}
