import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Tabs, {TabPane} from 'rc-tabs';
import Resumen from './Resumen';
import Detalle from './Detalle';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import { actions as printActions } from 'Utils/Print';

export default class ReporteMateriaPrimaMensual extends Component {
    componentWillMount() {
        const { getDataReporteMateriaPrimaMensual } = this.props;
        getDataReporteMateriaPrimaMensual();
    }

    imprimirReporte = (event) => {
        printActions.print('reporte-materia-prima-mensual');
    }

    render() {
        const {
            mpMensualAnioChange,
            mpMensualMesChange,
            descargarReporteMateriaPrimaMensual,
            setTabReporteMateriaPrimaMensual,
            pageReporteMateriaPrimaMensual,
            descargarDetalleReporteMateriaPrimaMensual,
            reporte_mp_mensual: reporte,
        } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="MATERIA PRIMA MENSUAL"
                    noBorder
                    subtitulo="Reporte">
                    <Tabs
                        activeKey={reporte.tab}
                        tabBarPosition="top"
                        onChange={tab_reporte =>{setTabReporteMateriaPrimaMensual(tab_reporte)}}
                        renderTabBar={() => <TabBar />}
                        renderTabContent={() => <TabContent />}
                    >
                        <TabPane tab="DETALLE" key="DETALLE">
                            <CardEmpty>
                                <FiltrosHeader {...this.props}
                                    filtroMesChange={mpMensualMesChange} mes_reporte={reporte.mes}
                                    filtroAnioChange={mpMensualAnioChange} anio_reporte={reporte.anio}
                                    botonExportar={descargarDetalleReporteMateriaPrimaMensual}
                                />
                            </CardEmpty>
                            <div className="mb-4"></div>
                            <Detalle {...this.props} pageChange={pageReporteMateriaPrimaMensual} page={reporte.page}></Detalle>
                        </TabPane>
                        <TabPane tab="RESUMEN" key="RESUMEN">
                            <CardEmpty>
                                <FiltrosHeader {...this.props}
                                    filtroMesChange={mpMensualMesChange} mes_reporte={reporte.mes}
                                    filtroAnioChange={mpMensualAnioChange} anio_reporte={reporte.anio}
                                    botonImprimir={this.imprimirReporte} botonExportar={descargarReporteMateriaPrimaMensual}
                                />
                            </CardEmpty>
                            <div className="mb-4"></div>
                            <Resumen {...this.props}></Resumen>
                        </TabPane>
                    </Tabs>
                </Card>
            </div>
        )
    }
}
