import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Tabs, {TabPane} from 'rc-tabs';
import Resumen from './Resumen';
import Detalle from './Detalle';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import { PrintContainer, actions as printActions } from 'Utils/Print';

export default class ReporteLiquidacionSemanal extends Component {
    componentWillMount() {
        const { dataReporteLiqSemanal } = this.props;
        dataReporteLiqSemanal();
    }

    imprimirBoleta = (event) => {
        printActions.print('reporte-liq-semanal');
    }

    render() {
        const {
            filtroReporteLiqSemanalFincas,
            filtroReporteLiqSemanalSemana,
            filtroReporteLiqSemanalAnio,
            filtro_fincas_reporte_liq_semanal,
            filtro_semanas_reporte_liq_semanal,
            filtro_anio_reporte_liq_semanal,
            descargarReporteLiqSemanal,
            descargarDetalleReporteLiqSemanal,
            tab_reporte_liq_semanal,
            setTabReporteLiqSemanal,
            page_reporte_liq_semanal,
            pageReporteLiqSemanalAnioChange
        } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="LIQUIDACIÓN SEMANAL"
                    noBorder
                    subtitulo="Reporte">
                    <Tabs
                        activeKey={tab_reporte_liq_semanal}
                        tabBarPosition="top"
                        onChange={tab_reporte =>{setTabReporteLiqSemanal(tab_reporte)}}
                        renderTabBar={() => <TabBar />}
                        renderTabContent={() => <TabContent />}
                    >
                        <TabPane tab="DETALLE" key="DETALLE">
                            <CardEmpty>
                                <FiltrosHeader {...this.props}
                                    filtroFincasChange={filtroReporteLiqSemanalFincas} fincas_ids={filtro_fincas_reporte_liq_semanal}
                                    filtroSemanaChange={filtroReporteLiqSemanalSemana} semana_reporte={filtro_semanas_reporte_liq_semanal}
                                    filtroAnioChange={filtroReporteLiqSemanalAnio} anio_reporte={filtro_anio_reporte_liq_semanal}
                                    botonExportar={descargarDetalleReporteLiqSemanal}
                                />
                            </CardEmpty>
                            <div className="mb-4"></div>
                            <Detalle {...this.props} pageChange={pageReporteLiqSemanalAnioChange} page={page_reporte_liq_semanal}></Detalle>
                        </TabPane>
                        <TabPane tab="RESUMEN" key="RESUMEN">
                            <CardEmpty>
                                <FiltrosHeader {...this.props}
                                    filtroFincasChange={filtroReporteLiqSemanalFincas} fincas_ids={filtro_fincas_reporte_liq_semanal}
                                    filtroSemanaChange={filtroReporteLiqSemanalSemana} semana_reporte={filtro_semanas_reporte_liq_semanal}
                                    filtroAnioChange={filtroReporteLiqSemanalAnio} anio_reporte={filtro_anio_reporte_liq_semanal}
                                    botonImprimir={this.imprimirBoleta} botonExportar={descargarReporteLiqSemanal}
                                />
                            </CardEmpty>
                            <div className="mb-4"></div>
                            <Resumen {...this.props}></Resumen>
                        </TabPane>
                    </Tabs>
                </Card>
            </div>
        )
    }
}
