import React, { Component } from 'react'
import LoadMask from '../../../Utils/LoadMask/LoadMask'
import CardEmpty from '../../../Utils/Cards/CardEmpty'
import Grid from '../../../Utils/Grid'
import { standardActions } from '../../../Utils/Grid/StandardActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment'
import { RenderKilogramo, RenderCM, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField'
import _ from 'lodash'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { renderLegendReporteAnual } from '../components'

const DRC = 10
const KG = 20

const renderToolTip = props => {
    const { active, payload, label, grafica_reporte } = props;
    if (active && payload) {
        return (
            <CardEmpty noShadow>
                <div className="d-flex flex-column pb-2">
                    {payload.map(entry => {
                        if (entry.name.match('drc') !== null) {
                            return <span className="mb-0">DRC {entry.name.slice(entry.name.indexOf('_') + 1, entry.name.length)}: <RenderNumberPercentage value={entry.value/100}/></span>
                        }
                        else {
                            return <span className="mb-0">KG {entry.name.slice(entry.name.indexOf('_') + 1, entry.name.length)}: <RenderKilogramo value={entry.value}/></span>
                        }
                    })

                    }
                </div>
            </CardEmpty>
        )
    }
    return null
}

const sumatoriaDRCPromedio = recepciones => {
    let drc_promedio = 0
    if (recepciones.length > 0) {
        recepciones.forEach(recepcion => {
            drc_promedio += recepcion.analisis_laboratorio.drc_promedio
        })
        return drc_promedio /= recepciones.length
    }
    else return 0
}

const sumatoriaKg = recepciones => {
    let kilogramos = 0
    recepciones.forEach(recepcion => {
        kilogramos += recepcion.peso_neto
    })
    return kilogramos
}

const getRecepcionesPorMes = recepciones => {
    let recepciones_por_mes = []
    let years = []
    const results = recepciones.all_results
    if (results && results.length > 0)  {
        results.forEach(recepcion => {
            // Verificar si los años buscados están registrados en el arreglo
            if (years.indexOf(moment(recepcion.ingreso.fecha_entrada).format('YYYY')) == -1) {
                years.push(moment(recepcion.ingreso.fecha_entrada).format('YYYY'))
            }
        })
    }

    years.forEach(year => {
        let entry = {
            year: year,
            enero: {drc: 0, kg: 0},
            febrero: {drc: 0, kg: 0},
            marzo: {drc: 0, kg: 0},
            abril: {drc: 0, kg: 0},
            mayo: {drc: 0, kg: 0},
            junio: {drc: 0, kg: 0},
            julio: {drc: 0, kg: 0},
            agosto: {drc: 0, kg: 0},
            septiembre: {drc: 0, kg: 0},
            octubre: {drc: 0, kg: 0},
            noviembre: {drc: 0, kg: 0},
            diciembre: {drc: 0, kg: 0}
        }
        let recepcionesEnero = results.filter(recepcion => ((moment(recepcion.ingreso.fecha_entrada).format('YYYY') === year && moment(recepcion.ingreso.fecha_entrada).format('MMMM') === 'enero')))
        let recepcionesFebrero = results.filter(recepcion => (moment(recepcion.ingreso.fecha_entrada).format('YYYY') === year && moment(recepcion.ingreso.fecha_entrada).format('MMMM') === 'febrero'))
        let recepcionesMarzo = results.filter(recepcion => ((moment(recepcion.ingreso.fecha_entrada).format('YYYY') === year && moment(recepcion.ingreso.fecha_entrada).format('MMMM') === 'marzo')))
        let recepcionesAbril = results.filter(recepcion => ((moment(recepcion.ingreso.fecha_entrada).format('YYYY') === year && moment(recepcion.ingreso.fecha_entrada).format('MMMM') === 'abril')))
        let recepcionesMayo = results.filter(recepcion => ((moment(recepcion.ingreso.fecha_entrada).format('YYYY') === year && moment(recepcion.ingreso.fecha_entrada).format('MMMM') === 'mayo')))
        let recepcionesJunio = results.filter(recepcion => ((moment(recepcion.ingreso.fecha_entrada).format('YYYY') === year && moment(recepcion.ingreso.fecha_entrada).format('MMMM') === 'junio')))
        let recepcionesJulio = results.filter(recepcion => ((moment(recepcion.ingreso.fecha_entrada).format('YYYY') === year && moment(recepcion.ingreso.fecha_entrada).format('MMMM') === 'julio')))
        let recepcionesAgosto = results.filter(recepcion => ((moment(recepcion.ingreso.fecha_entrada).format('YYYY') === year && moment(recepcion.ingreso.fecha_entrada).format('MMMM') === 'agosto')))
        let recepcionesSeptiembre = results.filter(recepcion => ((moment(recepcion.ingreso.fecha_entrada).format('YYYY') === year && moment(recepcion.ingreso.fecha_entrada).format('MMMM') === 'septiembre')))
        let recepcionesOctubre = results.filter(recepcion => ((moment(recepcion.ingreso.fecha_entrada).format('YYYY') === year && moment(recepcion.ingreso.fecha_entrada).format('MMMM') === 'octubre')))
        let recepcionesNoviembre = results.filter(recepcion => ((moment(recepcion.ingreso.fecha_entrada).format('YYYY') === year && moment(recepcion.ingreso.fecha_entrada).format('MMMM') === 'noviembre')))
        let recepcionesDiciembre = results.filter(recepcion => ((moment(recepcion.ingreso.fecha_entrada).format('YYYY') === year && moment(recepcion.ingreso.fecha_entrada).format('MMMM') === 'diciembre')))
        entry.enero.drc = sumatoriaDRCPromedio(recepcionesEnero)
        entry.enero.kg = sumatoriaKg(recepcionesEnero)
        entry.febrero.drc = sumatoriaDRCPromedio(recepcionesFebrero)
        entry.febrero.kg = sumatoriaKg(recepcionesFebrero)
        entry.marzo.drc = sumatoriaDRCPromedio(recepcionesMarzo)
        entry.marzo.kg = sumatoriaKg(recepcionesMarzo)
        entry.abril.drc = sumatoriaDRCPromedio(recepcionesAbril)
        entry.abril.kg = sumatoriaKg(recepcionesAbril)
        entry.mayo.drc = sumatoriaDRCPromedio(recepcionesMayo)
        entry.mayo.kg = sumatoriaKg(recepcionesMayo)
        entry.junio.drc = sumatoriaDRCPromedio(recepcionesJunio)
        entry.junio.kg = sumatoriaKg(recepcionesJunio)
        entry.julio.drc = sumatoriaDRCPromedio(recepcionesJulio)
        entry.julio.kg = sumatoriaKg(recepcionesJulio)
        entry.agosto.drc = sumatoriaDRCPromedio(recepcionesAgosto)
        entry.agosto.kg = sumatoriaKg(recepcionesAgosto)
        entry.septiembre.drc = sumatoriaDRCPromedio(recepcionesSeptiembre)
        entry.septiembre.kg = sumatoriaKg(recepcionesSeptiembre)
        entry.octubre.drc = sumatoriaDRCPromedio(recepcionesOctubre)
        entry.octubre.kg = sumatoriaKg(recepcionesOctubre)
        entry.noviembre.drc = sumatoriaDRCPromedio(recepcionesNoviembre)
        entry.noviembre.kg = sumatoriaKg(recepcionesNoviembre)
        entry.diciembre.drc = sumatoriaDRCPromedio(recepcionesDiciembre)
        entry.diciembre.kg = sumatoriaKg(recepcionesDiciembre)
        
        recepciones_por_mes.push(entry)
    })
    return recepciones_por_mes
}

const getGraficaReporte = resumen => {
    // Aquí se le va a dar formato la data que tiene la gráfica
    let data_grafica = [
        {name: 'ENE'},
        {name: 'FEB'},
        {name: 'MAR'},
        {name: 'ABR'},
        {name: 'MAY'},
        {name: 'JUN'},
        {name: 'JUL'},
        {name: 'AGO'},
        {name: 'SEP'},
        {name: 'OCT'},
        {name: 'NOV'},
        {name: 'DIC'}
    ]
    if (resumen && resumen.length > 0) {
        resumen.forEach(entry => {
            data_grafica[data_grafica.findIndex(element => element.name == 'ENE')][`kg_${entry.year}`] = entry.enero.kg
            data_grafica[data_grafica.findIndex(element => element.name == 'ENE')][`drc_${entry.year}`] = entry.enero.drc * 100
            data_grafica[data_grafica.findIndex(element => element.name == 'FEB')][`kg_${entry.year}`] = entry.febrero.kg
            data_grafica[data_grafica.findIndex(element => element.name == 'FEB')][`drc_${entry.year}`] = entry.febrero.drc * 100
            data_grafica[data_grafica.findIndex(element => element.name == 'MAR')][`kg_${entry.year}`] = entry.marzo.kg
            data_grafica[data_grafica.findIndex(element => element.name == 'MAR')][`drc_${entry.year}`] = entry.marzo.drc * 100
            data_grafica[data_grafica.findIndex(element => element.name == 'ABR')][`kg_${entry.year}`] = entry.abril.kg
            data_grafica[data_grafica.findIndex(element => element.name == 'ABR')][`drc_${entry.year}`] = entry.abril.drc * 100
            data_grafica[data_grafica.findIndex(element => element.name == 'MAY')][`kg_${entry.year}`] = entry.mayo.kg
            data_grafica[data_grafica.findIndex(element => element.name == 'MAY')][`drc_${entry.year}`] = entry.mayo.drc * 100
            data_grafica[data_grafica.findIndex(element => element.name == 'JUN')][`kg_${entry.year}`] = entry.junio.kg
            data_grafica[data_grafica.findIndex(element => element.name == 'JUN')][`drc_${entry.year}`] = entry.junio.drc * 100
            data_grafica[data_grafica.findIndex(element => element.name == 'JUL')][`kg_${entry.year}`] = entry.julio.kg
            data_grafica[data_grafica.findIndex(element => element.name == 'JUL')][`drc_${entry.year}`] = entry.julio.drc * 100
            data_grafica[data_grafica.findIndex(element => element.name == 'AGO')][`kg_${entry.year}`] = entry.agosto.kg
            data_grafica[data_grafica.findIndex(element => element.name == 'AGO')][`drc_${entry.year}`] = entry.agosto.drc * 100
            data_grafica[data_grafica.findIndex(element => element.name == 'SEP')][`kg_${entry.year}`] = entry.septiembre.kg
            data_grafica[data_grafica.findIndex(element => element.name == 'SEP')][`drc_${entry.year}`] = entry.septiembre.drc * 100
            data_grafica[data_grafica.findIndex(element => element.name == 'OCT')][`kg_${entry.year}`] = entry.octubre.kg
            data_grafica[data_grafica.findIndex(element => element.name == 'OCT')][`drc_${entry.year}`] = entry.octubre.drc * 100
            data_grafica[data_grafica.findIndex(element => element.name == 'NOV')][`kg_${entry.year}`] = entry.noviembre.kg
            data_grafica[data_grafica.findIndex(element => element.name == 'NOV')][`drc_${entry.year}`] = entry.noviembre.drc * 100
            data_grafica[data_grafica.findIndex(element => element.name == 'DIC')][`kg_${entry.year}`] = entry.diciembre.kg
            data_grafica[data_grafica.findIndex(element => element.name == 'DIC')][`drc_${entry.year}`] = entry.diciembre.drc * 100
        })
    }

    return data_grafica
}
class Reporte extends Component {
    render() {
        const { pageChange, page, loader, reporte_anual, graficaReporte} = this.props
        const TIPO_MATERIA = reporte_anual.tipo_materia
        var years = []
        switch(TIPO_MATERIA) {
            case 'LATEX':
                reporte_anual.recepciones = reporte_anual.recepciones_latex
                years = reporte_anual.anios_latex
                break
            case 'SOLIDO':
                reporte_anual.recepciones = reporte_anual.recepciones_solido
                years = reporte_anual.anios_solido
        }
        var recepcionesPorMes = { results: [] }
        var data_grafica = []
        
        recepcionesPorMes = { results: getRecepcionesPorMes(reporte_anual.recepciones) }
        data_grafica = getGraficaReporte(recepcionesPorMes.results)

        return (
            <LoadMask loading={loader} blur>
                <div className="mb-4">
                    <CardEmpty>
                        <div className="d-flex flex-column">
                            <div className="flex-fill d-flex justify-content-center">
                                <ResponsiveContainer width="85%" height="100%" minWidth={500} minHeight={500}>
                                    <BarChart width={730} height={250} data={data_grafica}>
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="name"/>
                                        {reporte_anual.grafica_reporte.value === DRC ?
                                                <YAxis unit="%"/>
                                            :
                                                <YAxis unit="Kg"/>
                                        }
                                        <Tooltip content={renderToolTip} grafica_reporte={reporte_anual.grafica_reporte}/>
                                        <Legend content={renderLegendReporteAnual} graficaReporte={graficaReporte}
                                            grafica_reporte={reporte_anual.grafica_reporte} align="left" seleccionGrafica verticalAlign="middle"
                                            layout="vertical" iconType="circle"
                                            title="TIPO DE DATO"
                                        />
                                        {reporte_anual.grafica_reporte.value === DRC && (
                                            years.map(year => (
                                                <Bar
                                                    dataKey={`drc_${year.value}`}
                                                    value={year.value}
                                                    fill={year.value == moment().endOf('year').format('YYYY') ? '#215273' : year.value == moment().subtract(1, 'years').format('YYYY') ? '#359d9e' : '#55c595'}
                                                />
                                            ))
                                        )}
                                        {reporte_anual.grafica_reporte.value === KG && (
                                            years.map(year => (
                                                <Bar
                                                    dataKey={`kg_${year.value}`}
                                                    value={year.value}
                                                    fill={year.value == moment().endOf('year').format('YYYY') ? '#215273' : year.value == moment().subtract(1, 'years').format('YYYY') ? '#359d9e' : '#55c595'}
                                                />
                                            ))
                                        )}
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </CardEmpty>
                </div>
                {/* {reporte_anual.recepciones &&
                    <div className="mb-3">
                        <CardEmpty>
                            <h5 className="verde-secundario">Recepciones</h5>
                            <Grid
                                hover striped loading={loader} data={reporte_anual.recepciones}
                                pagination onPageChange={pageChange} page={page}
                                className="pb-4 t-3"
                            >
                                <TableHeaderColumn dataField="id" isKey width="2%" dataFormat={cell => <div/>}/>
                                <TableHeaderColumn
                                    dataField="ingreso"
                                    dataAlign="5%"
                                    dataFormat={standardActions({ver_recepcion_reporte : "ver_recepcion_reporte"})}
                                />
                                <TableHeaderColumn
                                    dataField="ingreso"
                                    width="5%"
                                    dataFormat={cell => cell && cell.id ? cell.id.toString().padStart(3, 0) : '--'}
                                >
                                    ID
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="ingreso"
                                dataFormat={cell => cell && cell.fecha_entrada ? moment(cell.fecha_entrada).format('DD/MM/YYYY') : '--'}
                                >
                                    FECHA
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="ingreso"
                                    dataFormat={cell => cell && cell.finca && cell.finca.nombre ? cell.finca.nombre : '--'}
                                >
                                    FINCA
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="materia_prima"
                                    dataFormat={cell => _.get(cell, 'nombre', '--')}
                                >
                                    MATERIA PRIMA
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="peso_neto"
                                    dataAlign="right"
                                    dataFormat={cell => cell ? <RenderKilogramo value={cell} /> : '--'}
                                >
                                    KG HÚMEDOS
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="analisis_laboratorio"
                                    dataAlign="right"
                                    dataFormat={(cell, row) => cell && cell.drc_promedio && row.peso_neto ? <RenderKilogramo value={row.peso_neto*cell.drc_promedio} /> : '--'}
                                >
                                    KG SECOS
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="vaciados"
                                    dataFormat={cell => {
                                        let tipoVaciado = <span className="bold">MÚLTIPLE</span>;
                                        if (cell.length === 1) {
                                            tipoVaciado = _.get(cell[0], 'bodega.nombre', 'NO DEFINIDO');
                                        }
                                        return tipoVaciado;
                                    }}
                                >
                                    DESTINO
                                </TableHeaderColumn>
                                { TIPO_MATERIA === 'LATEX' && (
                                    <TableHeaderColumn dataField="peso_vaciados"
                                        dataAlign="right"
                                        dataFormat={cell => cell ? <RenderKilogramo value={cell}/> : '--'}
                                    >
                                        PESO VACIADO
                                    </TableHeaderColumn>
                                ) }
                                { TIPO_MATERIA === 'LATEX' && (
                                    <TableHeaderColumn dataField="peso_vaciados"
                                        dataAlign="right"
                                        dataFormat={(cell, row) => cell ? <RenderKilogramo value={row.peso_neto - cell}/> : '--'}
                                    >
                                        DIFERENCIA
                                    </TableHeaderColumn>
                                ) }
                                { TIPO_MATERIA === 'LATEX' && (
                                    <TableHeaderColumn dataField="peso_vaciados"
                                        dataAlign="right"
                                        dataFormat={(cell, row) => cell ? <RenderNumberPercentage value={(row.peso_neto - cell)/row.peso_neto}/> : '--'}
                                    >
                                        DIFERENCIA %
                                    </TableHeaderColumn>
                                ) }
                                <TableHeaderColumn dataField="id" width="2%" dataFormat={cell => <div></div>} />
                            </Grid>
                        </CardEmpty>
                    </div>
                } */}
                {recepcionesPorMes &&
                    <div className="mb-3">
                        <CardEmpty>
                            <h5 className="verde-secundario">DRC Promedio</h5>
                            <Grid
                                hover striped loading={loader} data={recepcionesPorMes}
                                pagination={false}
                                className="pb-4 t-3"
                            >
                                <TableHeaderColumn
                                    dataField="year"
                                    isKey
                                >
                                    AÑO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="enero"
                                    dataFormat={cell => cell && cell.drc ? <RenderNumberPercentage value={cell.drc}/> : '--'}
                                >
                                    ENE
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="febrero"
                                    dataFormat={cell => cell && cell.drc ?  <RenderNumberPercentage value={cell.drc}/> : '--'}
                                >
                                    FEB
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="marzo"
                                    dataFormat={cell => cell && cell.drc ?  <RenderNumberPercentage value={cell.drc}/> : '--'}
                                >
                                    MAR
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="abril"
                                    dataFormat={cell => cell && cell.drc ?  <RenderNumberPercentage value={cell.drc}/> : '--'}
                                >
                                    ABR
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="mayo"
                                    dataFormat={cell => cell && cell.drc ?  <RenderNumberPercentage value={cell.drc}/> : '--'}
                                >
                                    MAY
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="junio"
                                    dataFormat={cell => cell && cell.drc ?  <RenderNumberPercentage value={cell.drc}/> : '--'}
                                >
                                    JUN
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="julio"
                                    dataFormat={cell => cell && cell.drc ?  <RenderNumberPercentage value={cell.drc}/> : '--'}
                                >
                                    JUL
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="agosto"
                                    dataFormat={cell => cell && cell.drc ?  <RenderNumberPercentage value={cell.drc}/> : '--'}
                                >
                                    AGO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="septiembre"
                                    dataFormat={cell => cell && cell.drc ?  <RenderNumberPercentage value={cell.drc}/> : '--'}
                                >
                                    SEP
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="octubre"
                                    dataFormat={cell => cell && cell.drc ?  <RenderNumberPercentage value={cell.drc}/> : '--'}
                                >
                                    OCT
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="noviembre"
                                    dataFormat={cell => cell && cell.drc ?  <RenderNumberPercentage value={cell.drc}/> : '--'}
                                >
                                    NOV
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="diciembre"
                                    dataFormat={cell => cell && cell.drc ?  <RenderNumberPercentage value={cell.drc}/> : '--'}
                                >
                                    DIC
                                </TableHeaderColumn>
                            </Grid>
                        </CardEmpty>
                    </div>
                }
                {recepcionesPorMes &&
                    <div className="mb-5">
                        <CardEmpty>
                            <h5 className="verde-secundario">Kilogramos de materia prima</h5>
                            <Grid
                                hover striped loading={loader} data={recepcionesPorMes}
                                pagination={false}
                                className="pb-4 t-3"
                            >
                                <TableHeaderColumn
                                    dataField="year"
                                    isKey
                                >
                                    AÑO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="enero"
                                    dataFormat={cell => cell && cell.kg ? <RenderKilogramo value={cell.kg}/> : '--'}
                                >
                                    ENE
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="febrero"
                                    dataFormat={cell => cell && cell.kg ? <RenderKilogramo value={cell.kg}/> : '--'}
                                >
                                    FEB
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="marzo"
                                    dataFormat={cell => cell && cell.kg ? <RenderKilogramo value={cell.kg}/> : '--'}
                                >
                                    MAR
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="abril"
                                    dataFormat={cell => cell && cell.kg ? <RenderKilogramo value={cell.kg}/> : '--'}
                                >
                                    ABR
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="mayo"
                                    dataFormat={cell => cell && cell.kg ? <RenderKilogramo value={cell.kg}/> : '--'}
                                >
                                    MAY
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="junio"
                                    dataFormat={cell => cell && cell.kg ? <RenderKilogramo value={cell.kg}/> : '--'}
                                >
                                    JUN
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="julio"
                                    dataFormat={cell => cell && cell.kg ? <RenderKilogramo value={cell.kg}/> : '--'}
                                >
                                    JUL
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="agosto"
                                    dataFormat={cell => cell && cell.kg ? <RenderKilogramo value={cell.kg}/> : '--'}
                                >
                                    AGO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="septiembre"
                                    dataFormat={cell => cell && cell.kg ? <RenderKilogramo value={cell.kg}/> : '--'}
                                >
                                    SEP
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="octubre"
                                    dataFormat={cell => cell && cell.kg ? <RenderKilogramo value={cell.kg}/> : '--'}
                                >
                                    OCT
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="noviembre"
                                    dataFormat={cell => cell && cell.kg ? <RenderKilogramo value={cell.kg}/> : '--'}
                                >
                                    NOV
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="diciembre"
                                    dataFormat={cell => cell && cell.kg ? <RenderKilogramo value={cell.kg}/> : '--'}
                                >
                                    DIC
                                </TableHeaderColumn>
                            </Grid>
                        </CardEmpty>
                    </div>
                }
            </LoadMask>
        )
    }
}

export default Reporte