import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch, renderFieldCheck, SelectField } from '../../../Utils/renderField/renderField';
import CardEmpty from '../../../Utils/Cards/CardEmpty';

const vistas = [
    {value: '/', label: 'Dashboard'},
    {value: '/ingresos', label: 'Recepciones'},
    {value: '/tanques', label: 'Bodegas'},
    {value: '/laboratorio/analisis_latex', label: 'Laboratorio Látex'},
    {value: '/laboratorio/analisis_solido', label: 'Laboratorio Sólido'},
    {value: '/laboratorio/analisis_pilas', label: 'Laboratorio Pilas'},
    {value: '/producciones', label: 'Producción'},
    {value: '/compras', label: 'Compras'},
    {value: '/liquidaciones', label: 'Liquidaciones'}
]

let RolForm = props => {
    const { handleSubmit, permisos, editando } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column col-12">
                <div className="row col-md-4 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre_rol" className="m-0">NOMBRE DEL ROL</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field name="nombre_rol" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 px-2 mt-2">
                            <CardEmpty noShadow noPadding>
                                <div className="p-1 mt-1 mr-2">
                                    <label>ADMINISTRACIÓN</label>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Roles</label>
                                        <Field component={renderFieldCheck} className="form-control" name="roles" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Usuarios</label>
                                        <Field component={renderFieldCheck} className="form-control" name="usuarios" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Proveedores</label>
                                        <Field component={renderFieldCheck} className="form-control" name="usuarios_proveedor" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Laboratoristas</label>
                                        <Field component={renderFieldCheck} className="form-control" name="laboratoristas" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Clientes</label>
                                        <Field component={renderFieldCheck} className="form-control" name="clientes" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Bancos</label>
                                        <Field component={renderFieldCheck} className="form-control" name="bancos" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Propietarios</label>
                                        <Field component={renderFieldCheck} className="form-control" name="propietarios" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Perfiles de fincas</label>
                                        <Field component={renderFieldCheck} className="form-control" name="perfiles_fincas" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Transportistas</label>
                                        <Field component={renderFieldCheck} className="form-control" name="transportistas" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Vehiculos</label>
                                        <Field component={renderFieldCheck} className="form-control" name="vehiculos" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Pilotos</label>
                                        <Field component={renderFieldCheck} className="form-control" name="pilotos" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Materia Prima</label>
                                        <Field component={renderFieldCheck} className="form-control" name="materia_prima" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Pagos</label>
                                        <Field component={renderFieldCheck} className="form-control" name="pagos" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Fincas</label>
                                        <Field component={renderFieldCheck} className="form-control" name="fincas" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Acopios</label>
                                        <Field component={renderFieldCheck} className="form-control" name="acopios" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Bodegas</label>
                                        <Field component={renderFieldCheck} className="form-control" name="bodegas" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Contaminantes</label>
                                        <Field component={renderFieldCheck} className="form-control" name="contaminantes" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Anulación de recepción</label>
                                        <Field component={renderFieldCheck} className="form-control" name="anulacion" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Configuración</label>
                                        <Field component={renderFieldCheck} className="form-control" name="configuracion" />
                                    </div>
                                </div>
                            </CardEmpty>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 px-2 mt-2">
                            <div>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>RECEPCIONES</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Crear recepciones</label>
                                            <Field component={renderFieldCheck} className="form-control" name="recepciones_activas" />
                                        </div>
                                        {/* <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Finalizadas</label>
                                            <Field component={renderFieldCheck} className="form-control" name="recepciones_finalizadas" />
                                        </div> */}
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{marginTop: '1.112rem'}}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>DESPACHOS</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Crear despachos</label>
                                            <Field component={renderFieldCheck} className="form-control" name="despachos" />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{marginTop: '1.112rem'}}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>BODEGAS</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Tanques</label>
                                            <Field component={renderFieldCheck} className="form-control" name="tanques" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Pilas</label>
                                            <Field component={renderFieldCheck} className="form-control" name="pilas" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Almacenes</label>
                                            <Field component={renderFieldCheck} className="form-control" name="almacenes" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Analisis bodegas</label>
                                            <Field component={renderFieldCheck} className="form-control" name="analisis_bodega" />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{marginTop: '1.112rem'}}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>PRODUCCIÓN</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Crear producción</label>
                                            <Field component={renderFieldCheck} className="form-control" name="produccion" />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{marginTop: '1.112rem'}}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>COMPRAS</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Validar compras</label>
                                            <Field component={renderFieldCheck} className="form-control" name="crear_compras" />
                                        </div>
                                        {/* <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Validar compras</label>
                                            <Field component={renderFieldCheck} className="form-control" name="validar_compras" />
                                        </div> */}
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{marginTop: '1.112rem'}}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2" style={{height: "95.41px"}}>
                                        <div className="d-flex flex-column justify-content-start">
                                            <label>INICIAR EN PANTALLA</label>
                                            <Field component={SelectField} className="form-control" name="vista_inicial" options={vistas}/>
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 px-2 mt-2">
                            <CardEmpty noShadow noPadding>
                                <div className="p-1 mt-1 mr-2">
                                    <label>LABORATORIO LÁTEX</label>
                                    <div className="d-flex flex-row justify-content-between align-items-start">
                                        <label className="gris">Supervisor</label>
                                        <Field component={renderFieldCheck} className="form-control" name="supervisor_laboratorio"/>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start">
                                        <label className="gris">Análisis</label>
                                        <Field component={renderFieldCheck} className="form-control" name="analisis_laboratorio" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start">
                                        <label className="gris">Validar DRC</label>
                                        <Field component={renderFieldCheck} className="form-control" name="validar_drc" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start">
                                        <label className="gris">Historial</label>
                                        <Field component={renderFieldCheck} className="form-control" name="historial" />
                                    </div>
                                </div>
                            </CardEmpty>
                            <div style={{marginTop: '0.62rem'}}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>LABORATORIO SÓLIDO</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Análisis</label>
                                            <Field component={renderFieldCheck} className="form-control" name="analisis_laboratorio_solido" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Validar DRC</label>
                                            <Field component={renderFieldCheck} className="form-control" name="validar_drc_solido" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Historial</label>
                                            <Field component={renderFieldCheck} className="form-control" name="historial_solido" />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{marginTop: '0.62rem'}}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>LABORATORIO PILAS/TANQUES</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Análisis</label>
                                            <Field component={renderFieldCheck} className="form-control" name="pilas_analisis_laboratorio" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Validar DRC</label>
                                            <Field component={renderFieldCheck} className="form-control" name="pilas_analisis_laboratorio_validar_drc" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Historial</label>
                                            <Field component={renderFieldCheck} className="form-control" name="pilas_analisis_laboratorio_historial" />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{marginTop: '0.62rem'}}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>LIQUIDACIONES</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Crear liquidaciones</label>
                                            <Field component={renderFieldCheck} className="form-control" name="liquidaciones_pendientes" />
                                        </div>
                                        {/* <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Liquidadas</label>
                                            <Field component={renderFieldCheck} className="form-control" name="liquidaciones_liquidadas" />
                                        </div> */}
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{marginTop: '0.62rem'}}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>DATOS HISTÓRICOS</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Recepciones</label>
                                            <Field component={renderFieldCheck} className="form-control" name="historico_recepciones"/>
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Liquidaciones</label>
                                            <Field component={renderFieldCheck} className="form-control" name="historico_liquidaciones"/>
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 px-2 mt-2">
                            <CardEmpty noShadow noPadding>
                                <div className="p-1 mt-1 mr-2">
                                    <label>REPORTES</label>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Recepciones</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_recepciones" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Recepciones anuales</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_recepciones_anual" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Liquidaciones</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_liquidaciones" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Costo fletes</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_costo_fletes" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">DRC Recepciones</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_drc_recepciones" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">KG. secos recepciones</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_kg_secos_recepciones" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Producciones</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_producciones" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Laboratorio</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_laboratorio" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">VS. DRC</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_vs_drc" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Fluctuación precios</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_fluctuacion_precios" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Excedente de amoniaco</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_excedente_amoniaco" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Ubicación de fincas</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_ubicacion_fincas" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Traslados entre almacenes</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_traslados_materia" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">KG. anual</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_kg_secos_anual" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">DRC anual</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_drc_anual" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Liquidacion semanal</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_liq_semanal" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Materia Prima anual</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_materia_prima_anual" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Materia Prima mensual</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_materia_prima_mensual" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Bitacora</label>
                                        <Field component={renderFieldCheck} className="form-control" name="bitacora" />
                                    </div>
                                    {/* <div className="d-flex flex-row justify-content-between align-items-start">
                                        <label className="gris">Pilas</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_pilas" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start">
                                        <label className="gris">Almacenes</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_almacenes" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start">
                                        <label className="gris">Fincas</label>
                                        <Field component={renderFieldCheck} className="form-control" name="reporte_fincas" />
                                    </div> */}
                                </div>
                            </CardEmpty>
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/roles">CANCELAR</Link>
                    {editando ?
                        (<button type="submit" className="btn btn-primary" onClick={permisos()}>GUARDAR</button>)
                        :
                        (<button type="submit" className="btn btn-primary">GUARDAR</button>)
                    }
                </div>
            </div>
        </form>
    )
};
RolForm = reduxForm({
    form: 'RolForm',
    validate: data => {
        return validate(data, {
            'nombre_rol': validators.exists()('Campo requerido.'),
        })
    }
})(RolForm);

export default RolForm
