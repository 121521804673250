import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import {validate, validators} from 'validate-redux-form';
import {api} from "api";
import {AsyncSelectField, renderField, renderSwitch, renderSwitchMessage} from '../../../Utils/renderField/renderField';
import { SOLIDO } from '../../../../../utility/constants';

const getMateriasPrimas = async (search) => {
    try {
        const tipos = await api.get('tipo_materia_prima', {search});
        return tipos.results;
    } catch (err) {
        console.error(err);
        return [];
    }
};

let MateriaPrimaForm = (props) => {
    const {handleSubmit, showOptionLatexContaminado, disabledOptionLatexContaminado,  es_producto_F, is_editar, contable, } = props;
    console.log("props", props);
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">
                            {/* MATERIA PRIMA */}
                                { !es_producto_F ? 'MATERIA PRIMA' : 'PRODUCTO FINAL' }
                            </label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>

                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">
                            {/* TIPO DE MATERIA PRIMA */}
                                { !es_producto_F ? 'TIPO DE MATERIA PRIMA' : 'TIPO DE PRODUCTO' }
                            </label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="tipo_materia_prima"
                                valueKey="id"
                                labelKey="nombre"
                                label="Materia prima"
                                component={AsyncSelectField}
                                loadOptions={getMateriasPrimas}
                            />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="cve_sae" className="m-0">CLAVE SAE</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="cve_sae" component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="cve_sae_trading" className="m-0">CLAVE SAE TRADING</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="cve_sae_trading" component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                </div>
                {props.initialValues && props.initialValues.creando ? (
                <React.Fragment>
                <div className="row col-md-12 p-0">
                        <div className="col-md-6 p-0 d-flex align-items-center mt-3">
                            <div className="col-md-9 col-sm-9">
                                <label htmlFor="es_materia_prima" className="m-0">ES MATERIA PRIMA</label>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <Field
                                    name="es_materia_prima"
                                    component={renderSwitchMessage}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                { es_producto_F ? (
                    <div className="col-md-6 p-0 d-flex align-items-center mt-3">
                        <div className="col-md-9 col-sm-9">
                            <label htmlFor="latex_contaminado" className="m-0">CONTABILIZAR</label>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <Field
                                name="es_contable"
                                component={renderSwitchMessage}
                                type="text"
                            />
                        </div>
                    </div>
                    ) : null }
                </div>
                &nbsp;
                <div
                    className="px-4 text-wrap text-center mb-3"
                    style={{
                        color: 'red',
                        fontSize: '1.2em',
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    &nbsp; NOTA: Actualmente se creara {
                        es_producto_F ? contable ? ' un producto final que se contabilizará' : 'un producto final que no se contabilizará' : 'una materia prima'
                    }
                </div>
                </React.Fragment>
                ) : null}
                <div className="row col-md-12 p-0">
                { showOptionLatexContaminado ? (
                            <div className="col-md-6 p-0 d-flex align-items-center">
                                <div className="col-md-9 col-sm-9">
                                    <label htmlFor="latex_contaminado" className="m-0">ES LATEX CONTAMINADO</label>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <Field name="latex_contaminado" component={renderSwitch} type="text" className="form-control" disabled={disabledOptionLatexContaminado} />
                                </div>
                            </div>
                    ) : <div className="col-md-6 p-0 d-flex align-items-center"></div> }
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/materias_primas">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};

MateriaPrimaForm = reduxForm({
    form: 'MateriaPrimaForm',
    validate: data => validate(data, {
        nombre: validators.exists()('Campo requerido.'),
        tipo_materia_prima: validators.exists()('Campo requerido.'),
        cve_sae: validators.exists()('Campo requerido.'),
        cve_sae_trading: validators.exists()('Campo requerido.'),
    }),
})(MateriaPrimaForm);

MateriaPrimaForm = connect(state => {
    const store = state.materia_prima;
    const materias_latex_contaminado = store.materias_latex_contaminado ? store.materias_latex_contaminado : [];
    const form = state.form.MateriaPrimaForm;
    let showOptionLatexContaminado = false, disabledOptionLatexContaminado = false, es_producto_F = false, contable = true;
    if (form && form.values) {
        const values = form.values;
        if (values.tipo_materia_prima && values.tipo_materia_prima.tipo === SOLIDO) {
            showOptionLatexContaminado = true;
        }
        if (materias_latex_contaminado.length > 0) {
            if ((values.id && _.find(materias_latex_contaminado, { id : values.id}) === undefined) || (!values.id)) {
                disabledOptionLatexContaminado = true;
            }
        }
        if (values.es_materia_prima) {
            es_producto_F = false;
            values.es_contable = true;
        }
        else {
            es_producto_F = true;
        }
        if (values.es_contable) {
            contable = true;
        }
        else {
            contable = false;
        }
    }

    return {
        contable,
        es_producto_F,
        showOptionLatexContaminado,
        disabledOptionLatexContaminado,
    }
})(MateriaPrimaForm);

export default MateriaPrimaForm;
