import _ from "lodash";
import { LATEX } from '../../../../../../utility/constants';

const validador = (values, props) => {
    const errors = {};
    if(!values.peso_bascula)
        errors.peso_bascula = 'Campo requerido.';
    if(!values.peso_tara)
        errors.peso_tara = 'Campo requerido.';
    if(!values.peso_neto)
        errors.peso_neto = 'Campo requerido.';
    // if(!values.vehiculo)
    //     errors.vehiculo = 'Campo requerido.';
    // if(!values.tipo_materia)
    //     errors.tipo_materia = 'Campo requerido.';
    // if(!values.peso_bruto)
    //     errors.peso_bruto = 'Campo requerido.';


    const VaciadosArrayErrors = [];
    let total_vaciado = 0;
    if(!values.vaciados || values.vaciados.length <= 0){
        errors.errorVaciados = 'Se debe de agregar al menos una bodega para vaciar el producto.'
    }else {
        values.vaciados.forEach((vaciado, vaciadoIndex) => {
            const vaciadoErrors = {};
            const totalpeso = values.peso_neto;
            if (!vaciado.bodega){
                vaciadoErrors.bodega = 'La bodega es requerida';
                VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors
            }
            if(vaciado.bodega){
                const existe = _.find(values.vaciados, (p) => { return parseInt(p.bodega) === parseInt(vaciado.bodega)
                    && p.index !== vaciadoIndex });
                if(existe){
                    vaciadoErrors.bodega = 'La bodega ya se selecciono';
                    VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors
                }
            }
            if ( !vaciado.peso) {
                vaciadoErrors.peso = 'Campo requerido';
                VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors
            }else{
                total_vaciado += parseFloat(vaciado.peso);
            }
            if (values.tipo_materia === LATEX) {
                // Debido a que el vaciado de LÁTEX se hace en base a la cubicación de la pila, no es necesario que el PESO NETO
                // a vaciar concuerde con los pesos asignados en los vaciados.
                if (vaciado.altura_inicial === undefined) {
                    vaciadoErrors.altura_inicial = 'Campo requerido';
                    VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors
                } else {
                    if (vaciado.altura_inicial < 0) {
                        vaciadoErrors.altura_inicial = 'No puede ser negativo';
                        VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors;
                    } /*else if (vaciado.altura_inicial < _.get(vaciado, 'almacen.altura_utilizada', 0)) {
                        vaciadoErrors.altura_inicial = `La altura utilizada es de ${_.get(vaciado, 'almacen.altura_utilizada', 0).toFixed(2)} cms`;
                        VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors;
                    } */else if (vaciado.altura_final !== undefined && vaciado.altura_inicial >= vaciado.altura_final) {
                        vaciadoErrors.altura_inicial = 'Debe ser menor a la altura final';
                        VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors;
                    }
                }
                if (vaciado.altura_final === undefined) {
                    vaciadoErrors.altura_final = 'Campo requerido';
                    VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors
                } else {
                    if (vaciado.altura_final <= 0) {
                        vaciadoErrors.altura_final = 'Debe ser mayor a cero';
                        VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors;
                    } else if (vaciado.altura_final > _.get(vaciado, 'almacen.altura_maxima', 0)) {
                        vaciadoErrors.altura_final = `La altura máxima es de ${_.get(vaciado, 'almacen.altura_maxima', 0).toFixed(2)} cms`;
                        VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors;
                    } else if (vaciado.altura_inicial !== undefined && vaciado.altura_final <= vaciado.altura_inicial) {
                        vaciadoErrors.altura_final = 'Debe ser mayor a la altura inicial';
                        VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors;
                    } else if (_.get(vaciado, `almacen.cubicaciones_obj.${vaciado.altura_final}`, undefined) === undefined) {
                        vaciadoErrors.altura_final = 'No existe una cubicación para la altura final';
                        VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors;
                    }
                }
            } else {
                if (vaciado.peso === undefined) {
                    vaciadoErrors.peso = 'Campo requerido';
                    VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors;
                } else {
                    if (vaciado.peso <= 0) {
                        vaciadoErrors.peso = 'Debe ser mayor a cero';
                        VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors;
                    } else if (vaciado.peso > (_.get(vaciado, 'almacen.altura_maxima', 0) - _.get(vaciado, 'almacen.peso_total', 0))) {
                        vaciadoErrors.peso = 'Supera la capacidad disponible';
                        VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors;
                    }
                }
                if (totalpeso > total_vaciado) {
                    errors.errorVaciados = 'EL TOTAL A VACIAR ES MENOR AL PESO NETO'
                } else if (totalpeso < total_vaciado) {
                    errors.errorVaciados = 'EL TOTAL A VACIAR ES MAYOR AL PESO NETO'
                } else {
                    errors.errorVaciados = undefined;
                }
            }
            // if(Number(vaciado.peso_finca) <= 0){
            //     vaciadoErrors.peso_finca = "Debe ser una cantidad mayor a 0.";
            //     VaciadosArrayErrors[vaciadoIndex] = vaciadoErrors
            // }
        });
        if (VaciadosArrayErrors.length) {
            errors.vaciados = VaciadosArrayErrors
        }
    }
    return errors;
}

export default validador
